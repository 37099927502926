import React from 'react';
import { transition, media } from '../../utils/mixins';
import { Heading, Paragraph } from '@cheapreats/react-ui';
import { ButtonStyle } from '../Button.jsx';
import GiftCardImage from './giftcardimage.jsx';
import theme from '../../../theme/theme';
import styled from 'styled-components';
import { SectionContainer } from '../Section/SectionContainer';

const FeaturesPage = () => {
    return (
        <SectionContainer
            imageContent={<GiftCardImage />}
            textContent={[
                <Heading type="h2" color="primary" margin="10px 0">
                    Purchase an E-gift Card
                </Heading>,
                <Paragraph margin="10px 0">
                    Gift your friends the best gift of all, FREE FOOD! Click the
                    button to purchase a reloadable E-gift Card and send it to
                    any CheaprEats member.
                </Paragraph>,

                <Button href="#" margin="10px 0">
                    <Heading type="h4" color="white">
                        BUY E-GIFT CARD
                    </Heading>
                </Button>,
            ]}
            mediaFlexDirection="column"
            mediaMixin="phone"
        />
    );
};

const Button = styled(ButtonStyle)`
    @media (max-width: 375px) {
        flex-basis: 100%;
    }
    background-color: ${theme.colors.primary};
    h1 {
        color: ${theme.colors.background};
        justify-content: center;
    }
    ${transition(['transform'])}
    &:hover {
        transform: scale(0.95);
        background-color: ${theme.colors.primary};
    }
`;

export default FeaturesPage;
