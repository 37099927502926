import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { transition } from '../../utils/mixins';
import Img from 'gatsby-image';
import theme from '../../../theme/theme';
import styled from 'styled-components';
import { AiOutlineUser } from 'react-icons/ai';
import { Mixins } from '@cheapreats/react-ui';

const GiftCardImage = () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "leaves.png" }) {
                childImageSharp {
                    fluid(maxHeight: 800, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <ImageContainer>
            <Leaves>
                <Img fluid={data.file.childImageSharp.fluid} />
            </Leaves>
            <GiftCard>
                <IconBox>
                    <AiOutlineUser size="80%" />
                </IconBox>
                <GiftCardText>
                    <Number>
                        <h3>1307</h3>
                        <h3>9113</h3>
                        <h3>0592</h3>
                        <h3>2711</h3>
                    </Number>
                    <ValidFrom>
                        <h6>VALID FROM</h6>
                        <h4>11/23</h4>
                        <h5>Real Name</h5>
                    </ValidFrom>
                </GiftCardText>
            </GiftCard>
        </ImageContainer>
    );
};

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    height: 250px;
    ${transition(['transform'])}
    &:hover {
        transform: scale(0.95);
    }
    padding-top: 50px;
    padding-left: 100px;
    margin: auto;
    ${Mixins.media('tablet', 'padding-left: 50px; padding-top: 60px;')}
    ${Mixins.media('phone', 'padding-left: 20px;')}
`;

const Leaves = styled.div`
    z-index: 0;
    width: 450px;
    position: absolute;
    bottom: 0px;
    left: 32px;
    ${Mixins.media('tablet', 'width: 345px; bottom: 5px; left: 5px;')}
    ${Mixins.media('phone', 'width: 290px; bottom: 5px; left: 0px;')}
`;

const GiftCard = styled.div`
    z-index: 1;
    position: absolute;
    box-sizing: border-box;
    bottom: 20px;
    padding: 1rem;
    padding-bottom: 2rem;
    background-color: #fcb103;
    width: 300px;
    height: 200px;
    ${Mixins.media('tablet', 'width: 250px; ')}
    ${Mixins.media('phone', 'width: 240px; bottom: 25px;')}
    border-radius: 10px;
    color: ${theme.colors.background};
`;

const GiftCardText = styled.div`
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    h3,
    h4,
    h5,
    h6 {
        color: ${theme.colors.background};
    }
`;

const IconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 30%;
    height: 30%;
    background-color: #ff9900;
`;

const Number = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

const ValidFrom = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 32px;
`;

export default GiftCardImage;
