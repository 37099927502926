import GiftCardPage from '../components/giftcard/giftCardPage.jsx';
import { Layout } from '../components/Layout';
import React from 'react';

const GiftPage = () => (
    <Layout title="Gift Card">
        <GiftCardPage />
    </Layout>
);

export default GiftPage;
